<template>
	<div class="ele-body">
		<el-card shadow="never">

			<el-tabs v-model="activeName">
				<el-tab-pane label="启动页广告" name="first">
					<el-card shadow="never">
						<!-- 搜索表单 -->
						<el-form :model="table.where" class="ele-form-search d-flexspabet"
							@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
							<div>
								<el-button @click="addEdit=true" class="ele-btn-icon addbtn mb-20" size="small"
									v-if="permission.includes('sys:marketing_ads:add')">添加广告
								</el-button>
							</div>
							<div class="d-flex">
								<el-form-item label="区域:" label-width="44px">
									<el-select v-model="table.where.platform" placeholder="请选择" clearable
										class="input150">
										<el-option label="全部" value="0" />
										<el-option label="全国" value="1" />
										<el-option label="地区" value="2" />
									</el-select>
								</el-form-item>
								<el-form-item label="省:" label-width="35px" class="w-150">
									<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
										placeholder='请选择省' clearable>
										<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
											:label="option.name"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="市:" label-width="35px" class="w-150">
									<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)"
										placeholder='请选择市' clearable>
										<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
											:label="option.name"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="县/区:" label-width="62px" class="w-200">
									<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
										<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
											:label="option.name"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="类型:" label-width="50px" class="w-170">
									<el-select v-model="table.where.status" placeholder="请选择" clearable
										class="ele-fluid">
										<el-option label="进行中" value="1" />
										<el-option label="未开始" value="2" />
										<el-option label="已结束" value="3" />
									</el-select>
								</el-form-item>
								<div class="ml-20 mb-20">
									<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
										class="ele-btn-icon">搜索
									</el-button>
									<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
								</div>
							</div>
						</el-form>
						<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
							highlight-current-row :stripe=true>
							<template slot-scope="{index}">
								<el-table-column type="selection" width="45" align="center" fixed="left" />
								<el-table-column type="index" :index="index" label="编号" width="60" align="center"
									fixed="left" show-overflow-tooltip />
								<el-table-column label="添加时间" show-overflow-tooltip min-width="160">
									<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
								</el-table-column>
								<el-table-column label="结束时间" show-overflow-tooltip min-width="160">
									<template slot-scope="{row}">{{ row.end_time*1000 | toDateString }}</template>
								</el-table-column>
								<el-table-column prop="rest_time" label="剩余时间" show-overflow-tooltip min-width="180" />
								<el-table-column prop="set_address" label="申请地区" show-overflow-tooltip
									min-width="250" />
								<el-table-column prop="type" label="广告类型" show-overflow-tooltip min-width="120">
									<template slot-scope="scope">
										<span>{{scope.row.type==1?'引导页广告':'宣传广告'}}{{scope.row.rush}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="send_app" label="发送版" show-overflow-tooltip min-width="110">
									<template slot-scope="scope">
										<!-- <span>{{scope.row.send_app_name}}</span> -->
										<span>{{scope.row.send_app==1?'可蚁点司机版':(scope.row.send_app==2?'可蚁点商家版':(scope.row.send_app==3?'可蚁点用户版':(scope.row.send_app==4?'全平台':'可蚁点跑腿版')))}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="platform" label="地区" show-overflow-tooltip min-width="130">
									<template slot-scope="scope">
										<span>{{scope.row.platform==1?'全部地区':'当前地区'}}{{scope.row.rush}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="day_num" label="时长(天)" show-overflow-tooltip min-width="80" />
								<el-table-column prop="company_name" label="广告商名称" show-overflow-tooltip
									min-width="240" />
								<el-table-column prop="company_phone" label="联系方式" show-overflow-tooltip
									min-width="120" />
								<el-table-column prop="sort_order" label="排序" show-overflow-tooltip min-width="50" />
								<el-table-column label="操作" width="150px" align="center" :resizable="false"
									fixed="right">
									<template slot-scope="{row}">
										<el-link @click="edit(row)" icon="el-icon-view" type="primary"
											:underline="false" v-if="permission.includes('sys:marketing_ads:edit')">查看
										</el-link>
										<el-link icon="el-icon-delete" type="danger" :underline="false"
											@click="remove(row)" v-if="permission.includes('sys:marketing_ads:delete')">删除
										</el-link>
									</template>
								</el-table-column>
							</template>
						</ele-data-table>
					</el-card>

				</el-tab-pane>
				<el-tab-pane label="审核列表" name="second">
					<el-card shadow="never">
						<!-- 搜索表单 -->
						<el-form :model="table1.where" label-width="77px" class="ele-form-search d-flex f-end "
							@keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
							<div class="d-flex">
								<el-form-item label="省:" label-width="35px" class="w-170">
									<el-select v-model="table1.where.pid" @change="handleChangeProv1(table1.where.pid)"
										placeholder='请选择省' clearable>
										<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
											:label="option.name"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="市:" label-width="35px" class="w-150">
									<el-select v-model="table1.where.cid" @change="handleChangeCity1(table1.where.cid)"
										placeholder='请选择市' clearable>
										<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
											:label="option.name"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="县/区:" label-width="62px" class="w-200">
									<el-select v-model="table1.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
										<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
											:label="option.name"></el-option>
									</el-select>
								</el-form-item>
								<div class="ml-20">
									<el-button type="primary" @click="$refs.table1.reload()" icon="el-icon-search"
										class="ele-btn-icon">搜索
									</el-button>
									<el-button @click="(table.where={})&&$refs.table1.reload()">重置</el-button>
								</div>
							</div>
						</el-form>
						<ele-data-table ref="table1" :config="table1" :choose.sync="choose" height="calc(100vh - 315px)"
							highlight-current-row :stripe=true>
							<template slot-scope="{index}">
								<el-table-column type="selection" width="45" align="center" fixed="left" />
								<el-table-column type="index" :index="index" label="编号" width="60" align="center"
									fixed="left" show-overflow-tooltip />
								<el-table-column label="添加日期" show-overflow-tooltip min-width="160">
									<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
								</el-table-column>
								<el-table-column prop="set_address" label="申请地区" show-overflow-tooltip
									min-width="250" />
								<el-table-column prop="fee" label="金额(元)" show-overflow-tooltip min-width="100" />
								<el-table-column prop="day_num" label="时长(天)" show-overflow-tooltip min-width="100" />
								<el-table-column prop="company_name" label="广告商名称" show-overflow-tooltip
									min-width="250" />
								<el-table-column prop="company_phone" label="联系方式" show-overflow-tooltip
									min-width="150" />
								<el-table-column prop="title" label="引导页资料" show-overflow-tooltip min-width="220" />
								<el-table-column prop="type" label="审核状态" show-overflow-tooltip min-width="80">
									<template slot-scope="scope">
										<span>{{scope.row.status==0?'待审核':(scope.row.status==1?'已通过':(scope.row.status==2?'已驳回':'已失效'))}}{{scope.row.rush}}</span>
									</template>
								</el-table-column>

								<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
									<template slot-scope="{row}">
										<el-link @click="edit(row)" icon="el-icon-view" type="primary"
											:underline="false" v-if="permission.includes('sys:marketing_check_ads:edit')">查看
										</el-link>
										<el-link icon="el-icon-delete" type="danger" :underline="false" @click="remove1(row)" 
										v-if="permission.includes('sys:marketing_check_ads:delete')">删除</el-link>
										<el-dropdown @command="command => dropClick(command,row)" v-if='row.status==0'>
											<span class="el-dropdown-link" style="margin-left: 10px;">
												<el-link type="primary" icon="el-icon-more" :underline="false">
												</el-link>
											</span>
											<el-dropdown-menu slot="dropdown">
												<el-dropdown-item icon="el-icon-view" command="through"
													v-if="permission.includes('sys:marketing_check_ads:success')">通过
												</el-dropdown-item>
												<el-dropdown-item icon="el-icon-delete" command="rejected"
													v-if="permission.includes('sys:marketing_check_ads:error')">驳回
												</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</template>
								</el-table-column>
							</template>
						</ele-data-table>
					</el-card>
				</el-tab-pane>
				<el-tab-pane label="分润规则" name="three">
					<el-card shadow="never">
						<!-- 搜索表单 -->
						<el-form :model="table2.where" label-width="77px" class="ele-form-search d-flexspabet"
							@keyup.enter.native="$refs.table2.reload()" @submit.native.prevent>
							<div class="mb-15">
								<el-button @click="editRule=true" class="ele-btn-icon addbtn" size="small"
									v-if="permission.includes('sys:marketing_role:add')">添加规则</el-button>
							</div>
						</el-form>
						<ele-data-table ref="table2" :config="table2" :choose.sync="choose" height="calc(100vh - 315px)"
							highlight-current-row :stripe=true>
							<template slot-scope="{index}">
								<el-table-column type="selection" width="45" align="center" fixed="left" />
								<el-table-column type="index" :index="index" label="编号" width="60" align="center"
									fixed="left" show-overflow-tooltip />
								<el-table-column prop="type" label="分润规则" show-overflow-tooltip min-width="160">
									<template slot-scope="scope">
										{{scope.row.type==1?'总后台':(scope.row.type==2?'省级代理商':(scope.row.type==3)?'市级代理商':(scope.row.type==4?'县级运营商':(scope.row.type==5?'广告商':'')))}}
									</template>
								</el-table-column>
								<el-table-column prop="company" label="公司比例(%)" show-overflow-tooltip min-width="200" />
								<el-table-column prop="province" label="省份比例(%)" show-overflow-tooltip
									min-width="250" />
								<el-table-column prop="city" label="市比例(%)" show-overflow-tooltip min-width="130" />
								<el-table-column prop="county" label="县比例(%)" show-overflow-tooltip min-width="130" />
								<el-table-column prop="advertisers" label="广告商比例(%)" show-overflow-tooltip
									min-width="130" />
								<el-table-column label="添加时间" show-overflow-tooltip min-width="160">
									<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
								</el-table-column>
								<el-table-column label="操作" width="200px" align="center" :resizable="false"
									fixed="right">
									<template slot-scope="{row}">
										<el-link @click="edit1(row)" icon="el-icon-edit" type="primary"
											:underline="false" v-if="permission.includes('sys:marketing_role:edit')">编辑
										</el-link>
										<el-link icon="el-icon-delete" type="danger" :underline="false"
											@click="remove2(row)" v-if="permission.includes('sys:marketing_role:delete')">删除
										</el-link>
									</template>
								</el-table-column>
							</template>
						</ele-data-table>
					</el-card>
				</el-tab-pane>
			</el-tabs>
		</el-card>
		<!-- 查看广告内容弹窗 -->
		<el-dialog v-dialogDrag title="查看广告内容" :visible.sync="showEdit" @closed="editForm={}" :destroy-on-close="true"
			custom-class="ele-dialog-form" :lock-scroll="false" width="800px">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" label-width="150px">
					<div style="display: flex;">
						<div style="width: 100%;padding: 0 20px 0 10px;border-right: 1px solid #C6C6C6;">
							<el-form-item label="添加时间：" prop="create_time">
								{{ editForm.create_time*1000 | toDateString }}
							</el-form-item>
							<el-form-item label="结束时间：" prop="end_time">
								{{ editForm.end_time*1000 | toDateString }}
							</el-form-item>
							<!-- <el-form-item label="结束时间：" prop="end_time">
								{{ editForm.end_time*1000 | toDateString }}
							</el-form-item> -->
							<el-form-item label="广告商公司名：" prop="company_name">
								{{ editForm.company_name }}
							</el-form-item>
							<el-form-item label="负责人姓名：" prop="company_person">
								{{ editForm.company_person }}
							</el-form-item>
							<el-form-item label="负责人电话：" prop="company_phone">
								{{ editForm.company_phone }}
							</el-form-item>
							<el-form-item label="添加方式：" prop="is_admin">
								{{editForm.is_admin==1?'平台添加':'运营商申请添加'}}
							</el-form-item>
							<el-form-item label="申请地区：" prop="set_address">
								{{ editForm.set_address }}
							</el-form-item>
							<el-form-item label="广告类型：" prop="type">
								{{editForm.type==1?'引导页广告':'宣传广告'}}
							</el-form-item>
							<el-form-item label="显示版：" prop="send_app">
								<!-- {{editForm.send_app_name}} -->
								{{editForm.send_app==1?'可蚁点司机版':(editForm.send_app==2?'可蚁点商家版':(editForm.send_app==3?'可蚁点用户版':(editForm.send_app==4?'全平台':'可蚁点跑腿版')))}}
							</el-form-item>
						</div>
						<div style="width: 100%;">
							<el-form-item label="显示地区：" prop="platform">
								{{editForm.platform==1?'全部地区':'当前地区'}}
							</el-form-item>
							<el-form-item label="时长：" prop="day_num">
								{{editForm.day_num}} 天
							</el-form-item>
							<el-form-item label="金额(元)：" prop="fee">
								{{editForm.fee}}
							</el-form-item>
							<el-form-item label="显示图片：">
								<el-image style="width:100px;height:160px;margin-right:10px" :src="editForm.picture" fit="cover" :preview-src-list="[editForm.picture]"></el-image>
							</el-form-item>
							<el-form-item label="链接：" prop="url">
								{{editForm.url}}
							</el-form-item>
							<el-form-item label="描述：" prop="title">
								{{editForm.title}}
							</el-form-item>
						</div>
					</div>
				</el-form>
			</el-card>
		</el-dialog>
		<el-dialog v-dialogDrag title="添加广告" :visible.sync="addEdit" @closed="editShow={}" width="920px" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editShow" ref="editShow" label-width="140px" :rules="editRules">
					<div style="display: flex;">
						<div style="border-right:#e6e6e6 1px solid;width: 440px;">
							<el-form-item label="广告商公司名：" prop="company_name">
								<el-input v-model="editShow.company_name" autocomplete="off" placeholder="请输入广告商公司名" class="drop-down-first">
								</el-input>
							</el-form-item>
							<el-form-item label="负责人姓名：" prop="company_person">
								<el-input v-model="editShow.company_person" autocomplete="off" placeholder="请输入负责人姓名" class="drop-down-first">
								</el-input>
							</el-form-item>
							<el-form-item label="负责人电话：" prop="company_phone">
								<el-input v-model="editShow.company_phone" autocomplete="off" placeholder="请输入负责人电话" class="drop-down-first">
								</el-input>
							</el-form-item>
							<el-form-item label="显示版：" prop="send_app" style="width:360px">
								<el-select v-model="editShow.send_app" placeholder="请选择" clearable class="ele-fluid">
									<el-option label="全平台" value="4" />
									<!-- <el-option label="咱县打车用户版" value="1" />
									<el-option label="咱县打车司机版" value="2" />
									<el-option label="咱县打车跑腿版" value="3" />
									<el-option label="咱县打车商家版" value="4" /> -->
									<el-option label="可蚁点用户版" value="3" />
									<el-option label="可蚁点司机版" value="1" />
									<el-option label="可蚁点跑腿版" value="5" />
									<el-option label="可蚁点商家版" value="2" />
									<!-- <el-option label="可蚁点小程序版" value="9" /> -->
								</el-select>
							</el-form-item>
							<el-form-item label="投放区域：" prop="platform" style="width:360px">
								<el-select v-model="editShow.platform" placeholder="请选择" clearable class="ele-fluid">
									<el-option label="全国" value="1" />
									<el-option label="地区" value="2" />
								</el-select>
							</el-form-item>
							<el-form-item v-if="editShow.platform == 2" label="地区：" prop="pid">
								<el-select v-model="editShow.pid" @change="handleChangeProv(editShow.pid)"
									class="selectStyle mb-20" clearable placeholder="请选择省">
									<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
										:label="option.name"></el-option>
								</el-select>
								<el-select v-model="editShow.cid" @change="handleChangeCity(editShow.cid)"
									class="selectStyle mb-20" clearable placeholder="请选择市">
									<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
										:label="option.name"></el-option>
								</el-select>
								<el-select v-model="editShow.aid" @change="$forceUpdate()"
									class="selectStyle" clearable placeholder="请选择区/县">
									<el-option v-for="option in districtArr " :value="option.aid"
										:key="option.aid" :label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="广告类型：" prop="type" style="width:360px">
								<el-select v-model="editShow.type" placeholder="请选择广告类型" clearable
									class="ele-fluid drop-down-first">
									<el-option label="启动页广告" value="1" />
									<el-option label="宣传广告" value="2" />
								</el-select>
							</el-form-item>
							<el-form-item label="时长：" prop="day_num">
								<el-input v-model="editShow.day_num" autocomplete="off" placeholder="请输入时长(天)" class="drop-down-first">
								</el-input> 天
							</el-form-item>
						</div>
						<div>
							<el-form-item label="支付金额(元)：" prop="fee">
								<el-input v-model="editShow.fee" autocomplete="off" placeholder="请输入支付金额" class="drop-down-first"></el-input> 元
							</el-form-item>
							<el-form-item label="显示图片：" prop="img">
								<uploadImage :limit="1" v-model="editShow.picture" class="drop-down-first">
								</uploadImage>
							</el-form-item>
							<el-form-item label="描述：">
								<el-input v-model="editShow.title" rows="5" type="textarea" placeholder="请输入描述信息" autocomplete="off"
									class="drop-down-first"></el-input>
							</el-form-item>
							<el-form-item label="链接：">
								<el-input v-model="editShow.url" autocomplete="off" placeholder="请输入链接" class="drop-down-first"></el-input>
							</el-form-item>
						</div>
					</div>
				</el-form>
			</el-card>

			<div slot="footer">
				<el-button @click="addEdit=false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
		<el-dialog v-dialogDrag :title="editForm1.id?'编辑分润规则':'添加分润规则'" :visible.sync="editRule" @closed="editForm1={}"
			:destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm1" ref="editForm1" label-width="150px" :rules="editRules1">
					<el-form-item label="规则分类：" prop="type">
						<el-select v-model="editForm1.type" placeholder="请选择" class="input163" clearable>
							<el-option label="总后台" :value="1" />
							<el-option label="省级代理商" :value="2" />
							<el-option label="市级代理商" :value="3" />
							<el-option label="县级运营商" :value="4" />
							<el-option label="广告商" :value="5" />
						</el-select>
					</el-form-item>
					<el-form-item label="总后台：" prop="company">
						<el-input v-model="editForm1.company" autocomplete="off" class="input163"></el-input> %
					</el-form-item>
					<el-form-item label="省级代理商：" prop="province">
						<el-input v-model="editForm1.province" autocomplete="off" class="input163"></el-input> %
					</el-form-item>
					<el-form-item label="市级代理商：" prop="city">
						<el-input v-model="editForm1.city" autocomplete="off" class="input163"></el-input> %
					</el-form-item>
					<el-form-item label="县级运营商：" prop="county">
						<el-input v-model="editForm1.county" autocomplete="off" class="input163"></el-input> %
					</el-form-item>
					<el-form-item label="广告商：" prop="advertisers">
						<el-input v-model="editForm1.advertisers" autocomplete="off" class="input163"></el-input> %
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="editRule=false">取消</el-button>
				<el-button type="primary" @click="save1">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// import TinymceEditor from '@/components/TinymceEditor'
	import {
		mapGetters
	} from "vuex";
	import uploadImage from '@/components/uploadImage';
	export default {
		name: "SysNotice",
		data() {
			return {
				activeName: 'first',
				table: {
					url: '/marketing/ads_list',
					where: {}
				}, // 表格配置
				table1: {
					url: '/marketing/check_ads_list',
					where: {}
				}, // 表格配置
				table2: {
					url: '/marketing/dividend_list',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				addEdit: false, //添加弹框
				provArr: [],
				cityArr: [],
				districtArr: [],
				editForm: {
					source: 1,
					status: 1,
					is_top: 2
				}, // 表单数据
				editShow: {},
				editRule: false,
				editForm1: {},
				editRules: { // 表单验证规则
					company_name: [{
						required: true,
						message: '请输入广告商公司名',
						trigger: 'blur'
					}],
					company_person: [{
						required: true,
						message: '请输入负责人姓名',
						trigger: 'blur'
					}],
					company_phone: [{
						required: true,
						message: '请输入负责人电话',
						trigger: 'blur'
					}, {
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: '请输入正确的手机号',
						trigger: 'blur',
					}],
					send_app: [{
						required: true,
						message: '请选择显示版',
						trigger: 'change'
					}],
					platform: [{
						required: true,
						message: '请选择投放区域',
						trigger: 'change'
					}],
					type: [{
						required: true,
						message: '请选择广告类型',
						trigger: 'change'
					}],
					day_num: [{
						required: true,
						message: '请输入时长',
						trigger: 'blur'
					}],
					fee: [{
						required: true,
						message: '请输入时长',
						trigger: 'blur'
					}],
					pid: [{
						required: true,
						message: '请选择省市区',
						trigger: 'change'
					}],
				},
				editRules1: { // 表单验证规则
					type: [{
						required: true,
						message: '请选择规则分类',
						trigger: 'change'
					}],
					company: [{
						required: true,
						message: '请输入总后台分润',
						trigger: 'blur'
					}],
					province: [{
						required: true,
						message: '请输入省级代理商分润',
						trigger: 'blur'
					}],
					city: [{
						required: true,
						message: '请输入市级代理商分润',
						trigger: 'blur'
					}],
					county: [{
						required: true,
						message: '请输入县级运营商分润',
						trigger: 'blur'
					}],
					advertisers: [{
						required: true,
						message: '请输入广告商分润',
						trigger: 'blur'
					}],
				},
				// 自定义文件上传(这里使用把选择的文件转成blob演示)
				file_picker_callback: (callback, value, meta) => {
					let input = document.createElement('input');
					input.setAttribute('type', 'file');
					// 设定文件可选类型
					if (meta.filetype === 'image') {
						input.setAttribute('accept', 'image/*');
					} else if (meta.filetype === 'media') {
						input.setAttribute('accept', 'video/*');
					}
					input.onchange = () => {
						let file = input.files[0];
						let reader = new FileReader();
						reader.onload = (e) => {
							let blob = new Blob([e.target.result], {
								type: file.type
							});
							callback(URL.createObjectURL(blob));
						};
						reader.readAsArrayBuffer(file);
					}
					input.click();
				}
			}
		},

		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})

		},
		computed: {
			...mapGetters(["permission"]),
			editContent() {
				return {
					menubar: false,
					// file_picker_callback: this.file_picker_callback,
					skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' :
						'/tinymce/skins/ui/oxide',
					content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' :
						'/tinymce/skins/content/default/content.css'
				};
			}
		},
		components: {
			uploadImage
		},
		mounted() {},
		methods: {
			dropClick(command, row) {
				if (command === 'through') { // 通过
					this.check_success(row)
				} else if (command === 'rejected') { //驳回
					this.check_error(row)
				}
			},
			check_success(row) {
				this.$confirm('确定通过广告活动吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/marketing/check_ads', {
						id: row.id,
						status: 1
					}).then(res => {
						loading.close();
						let data = res.data
						if (data.code === 0) {
							this.$message({
								type: 'success',
								message: '通过成功'
							});
							this.$refs.table.reload();
							this.$refs.table1.reload();
						} else {
							this.$message.error(data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			check_error(row) {
				this.$confirm('确定驳回广告活动吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/marketing/check_ads', {
						id: row.id,
						status: 2
					}).then(res => {
						loading.close();
						let data = res.data
						if (data.code === 0) {
							this.$message({
								type: 'success',
								message: '驳回成功'
							});
							this.$refs.table1.reload();
						} else {
							this.$message.error(data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			/**
			 *选择省
			 **/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table1.where.cid = ''
					this.table1.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table1.where.aid = ''
				})
				}else{
					this.table1.where.pid = ''
					this.table1.where.aid = ''
				}
			},
			/* 显示编辑 */
			edit(row) {
				this.editForm =  row;
				this.showEdit = true;
			},
			edit1(row) {
				this.editForm1 = row;
				this.editRule = true;
			},
			/* 保存编辑 */
			save() {
				this.$refs['editShow'].validate((valid) => {
					if (valid) {
						if(this.editShow.picture){
							this.commentSave('/marketing/ads_add', this.editShow, 1)
						}else{
							this.$message.error('请上传显示图片');
						}
					} else {
								return false;
							}
					});
			},
			/* 分润规则保存编辑 */
			save1() {
				this.$refs['editForm1'].validate((valid) => {
					if (valid) {
						let sum = parseInt(this.editForm1.company) + parseInt(this.editForm1.province) + parseInt(this.editForm1
							.city) + parseInt(this.editForm1.county) + parseInt(this.editForm1.advertisers)
						if (sum == 100) {
							this.commentSave('/marketing/dividend_add', this.editForm1, 2)
						} else {
							this.$message.error('分润规则应为100%');
						}
					} else {
								return false;
							}
					});
			},
			// 保存
			commentSave(url, editForm, type) {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post(url, editForm).then(res => {
					loading.close();
					this.editRule = false
					if (res.data.code === 0) {
						this.addEdit = false;
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						if (type == 1) {
							// this.$refs.table.reload();
							this.$refs.table1.reload();
						} else {
							this.$refs.table2.reload();
						}
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			},

			/*启动页广告 删除 */
			remove(row) {
				this.commentRemove('确定删除此广告吗?', '/marketing/ads_delete', row, 1)
			},
			/*审核列表 删除 */
			remove1(row) {
				this.commentRemove('确定删除此广告吗?', '/marketing/ads_apply_delete', row, 2)
			},
			/* 分润规则删除 */
			remove2(row) {
				this.commentRemove('确定删除此规则吗?', '/marketing/dividend_del', row, 3)
			},
			// 删除方法
			commentRemove(msg, url, row, type) {
				this.$confirm(msg, '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post(url, {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							if (type == 1) {
								this.$refs.table.reload();
							}
							if (type == 2) {
								this.$refs.table1.reload();
							}
							if (type == 3) {
								this.$refs.table2.reload();
							}

						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}).catch(() => 0);
			},

		}
	}
</script>

<style scoped>
	.ele-block .el-upload .el-upload-dragger {
		width: 100%;
	}

	.el-form-item__label {
		width: 120px;
	}

	.drop-down-first {
		width: 220px;
	}

	/deep/.el-dialog {
		margin-top: 50px !important;
		width: 48%;
	}
</style>
